<template>
    <div>
        <!-- <grid cols="5">
            <stats-box
                v-for="i in 5"
                :key="i"
                :title="'Stats ' + i"
                :count="i"
                icon="icon-home"
            />
        </grid> -->
        <Events/>
    </div>
</template>

<script>
// import Grid from '../../common/components/grid/Grid.vue'
// import StatsBox from '../../common/components/stats-box/StatsBox.vue'
// import LineChart from '../../common/components/charts/LineChart.vue'
// import Chart from 'chart.js/auto'
// import axios from "axios";
// import {mapActions, mapState} from 'vuex';
import Events from './event-board/events.vue';

/*s
 * Import Components
 * */

export default {
    //   components: { Grid, StatsBox },
    name: "HomeIndex",
    components: { Events }
}



// async mounted () {
        //     this.loaded = false
        //     try {
        //         let  response  = await fetch('http://127.0.0.1:8000/api/crud/squid-game') 
        //         // http://127.0.0.1:8000/api/crud/analytics əsas link budu
        //         this.chartdata = response
        //         this.loaded = true
        //         console.log( response );
        //     } catch (e) {
        //         console.error(e)
        //     }
        //     axios.get('http://127.0.0.1:8000/api/crud/squid-game', {})
        //     .then(res=>console.log(res.data))
        //     .catch(err => console.log(err.response))
        // }
</script>

<style scoped>

</style>

